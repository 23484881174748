export const HomeTags = {
    title : "Interior Designers: Design your Home Interiors in 8 weeks",
    description : "Residential Interior Designers with 12+ years of expertise in India. Amazing Free Design options for Bedroom, Living Room, Lobby and kitchen | 8 weeks completion promise | No third party hidden charges | 5 years onsite warranty"
}

export const BedroomTags = {
    title : "Bedroom Designers: Bedroom interior designs | Cost Calculator",
    description : "Bedroom Interior Designers in India: Get Free Bedroom Designs exclusive for your room size with 8 weeks implementation guaranteed. Cost Calculator | No 3rd Party Hidden Charges | Maximum Onsite Warranty. Find wide range of Interior Designs for your Master Bedroom and Kid’s room at best price only @ Walscape.com"
}

export const LivingRoomTags = {
    title : "Best Room Interior Designers with 0 Designing Cost",
    description : "Living Room Interior Designers: Get wide range of Free Living Room designs exclusively for your room size. No 3rd Party Hidden Charges | 8 Weeks Completion Guarantee | Maximum Onsite Warranty. Find Contemporary, Modern, Rustic, Antique, Minimalist Designs for your Living Area at best price in India only @ Walscape.com"
}

export const DiningRoomTags = {
    title : "Dining room Interior Designers: Residential Interiors at best price",
    description : "Dining Room Interior Designers: Get Free Designs of your Dining area with 8 weeks completion promise. Cost Calculator for your room size | Maximum Warranty | No 3rd Party Hidden cost. Choose your Lobby Interiors from available design styles only @ Walscape.com"
}

export const KitchenTags = {
    title : "Modular Kitchen: Kitchen Interiors with Price Calculator | Walscape",
    description : "Modular Kitchen Designers: Get Free trendy Interior Designs for every kitchen shape from wide range of available Kitchen designs only @ Walscape. Price Calculator | 8 Weeks Completion Guarantee | No Hidden 3rd Party Cost"
}

export const CustomizationTags = {
    title : "Home Interior Designing services online at best price in India",
    description : "Customise your Residential Interiors at best price in India. Free Consultation | Free Design Options | 8 Weeks Completion | No Hidden Charges | Maximum onsite Warranty. Home Interiors is now made hassle free. Wide design options available for your Bedroom, Living Area, Lobby and Kitchen with multiple themes like contemporary, modern, rustic, minimalist, antique and traditional"
}

export const ContactUsTags = {
    title : "Contact Us : Help Center & Customer Care | Walscape",
    description : "Walscape Support team is more than happy to serve you for all your Home Interior needs, questions and queries. Feel free to contact us anytime 24/7 and get free interior consultation for your flat, bungalows and independent house"
}

export const AboutUsTags = {
    title : "About Us : Walscape"
}

export const AboutUsText = {
    MainHeading: "We are Walscape",
    MainDescription: "We do not assign a designer but we are the designers for you. We are committed to delivering more than just realistic images. As a team of interior designers and technologists, we are uniquely qualified to transform your home into a place which you’d fall in love with",

    Section1Heading: "The Story Behind Walscape",
    Section1Description: "Belonging to a working class family, we always found the Home Interior process very expensive, cumbersome and unreliable in quality finishing. By entering into the same profession and having a total 12+ years experience, Mrs. Neha Sharma discovered what made Home Interiors costly and inaccessible for working class families. Mr. Gunjan Dadhich & Mr. Ritwik Dadhich gained insights from people about their troubles in interiors and by dissecting all these issues, created an online solution",
    Section1Quote: "“Walscape is the answer to all your problems!”",

    Section2Heading:"Mission Statement",
    Section2Description:"Our aim is to eradicate the involvement of third party services from Interior Industry in order to provide a smoother and Hassle free experience for the premium beautification of Home Interiors with Standard Quality, Cost efficient and Time effective solutions",

    Section3Heading: "Meet our founders",
    Ceo: "Gunjan Dadhich",
    CeoText: "(Co founder & CEO)",
    Director: "Neha Sharma",
    DirectorText: "(Director & Principal Designer)",
    Coo: "Ritwik Dadhich",
    CooText: "(Co founder & COO)",

    Section4Heading: "Our Core Values",
    CoreValues: 
    [
        { Number: "01", Heading: "Client Commitment", Description: "We are defined by our commitment to deliver you the exceptional Home Interiors experience" }, 
        { Number: "02", Heading: "Work Quality", Description: "We are proud to deliver an unparalleled standard of excellence in our work by staying focussed in the Home Interiors to be meaningful to our clients" },
        { Number: "03", Heading: "Integrity", Description: "We do what’s right. We earn your trust by ensuring everything we do is reliable, with the highest level of integrity and good quality standards" },
        { Number: "04", Heading: "Personal Accountability", Description: "We believe we are a better company when each of our client feels satisfied and trust us, for which we are personally accountable to every prospect" }
    ]
};

export const CustomizationText = {
    MainHeading: "Customize your Home Interiors",
    MainDescription: "Home Interior Customization option provides you the exclusivity along with the ‘no boundary’ interior solution for your home sweet home. Let’s design your home beautifully together",

    StepsData: 
    [
        {   stepNumber : 'Step 1', 
            title: '1st Meeting to know your Requirements',
            description: 'The first meeting will be scheduled to know the requirements for the interiors of your Home project after the advance payment of Rs 8000, which will be adjusted in your overall project value' }, 
        {   stepNumber : 'Step 2', 
            title: '2nd Meeting for Finalisation of Design & Materials', 
            description: 'The second meeting will be scheduled for the finalisation of your 3-D design along with material selection. After Finalisation of Design, we will provide you  with the BoQ (Bill of Quantities) and every detail of the materials to be used in your interiors' },
        {   stepNumber : 'Step 3', 
            title: 'Let’s Start Implementing Your Interiors', 
            description: 'A single room or your entire home , our skilled professionals with more than 12+ years of experience will start implementing your interiors as per your convenience and complete it in 5 Stages' },
        {   stepNumber : 'Step 4', 
            title: 'Just Sit & Relax with Warranty card in your hands', 
            description: 'The warranty card ensures that your home is in safe hands .Therefore you don’t have to worry or panic about anything. We will make sure that you are involved in the process while you just relax and we complete your Home Sweet Home in Just 8 weeks as promised' }
    ],

    FaqData : 
    [
        {
            question : 'Why should i choose Walscape for my home interior project?',
            answer : 'Walscape has redefined Home interior ecosystem by providing you the expertise of best designers, maximum warranty, wide design range, no extra 3rd party / hidden charges and professional installation. Therefore furnishing your dream home is made easy.\n \n> Free Design options for every room size\nAt Walscape, we provide a wide range of multiple themed designs for every room size, including a Customization Option for Clients who are having some ideas in mind. Now feel your room with 360* panoramic view.\n \n> Quick Implementation & Warranty\nOnce we embark on a project, it’s our sole mission to implement your interiors within 8 weeks. We strive to relieve you from Quality Assurance pain, thereby insuring you with the maximum warranty to cover all your worries.\n \n> Transparency to the Fullest\nWe believe in providing Full Transparency which assures you of No 3rd party / hidden charges (either contractor or designer charges). We provide you an instant exact Quotation & materials description used in your Home project.',
            isMultiline : true,
            number: '01.',
        },
        {
            question : 'Can I customize just a part of my Home?',
            answer : 'Yes, our system is smooth enough to provide you every service regarding your Home interiors. We will work with you to design a part of your home, be it just your living room or your complete home',
            isMultiline : false,
            number: '02.',
        },
        {
            question : 'Can you renovate my existing home?',
            answer : 'Yes, we renovate existing home as well as provide home interior solution for new home',
            isMultiline : false,
            number: '03.',
        },
        {
            question : 'What are the advantages of Walscape Services over other interior designers/contractors?',
            answer : 'We want to eradicate the existing flaws in home interior methods which includes\n \n>Hidden designer charges for providing extra design options to satisfy all your needs and site visits charges plus hidden contractor charges\n \n>Traditional methods take a lot of time to implement interiors and there is no surety of the finished product as well\n \n>No warranty for the raw materials used in your interiors\n \n>Traditional methods do not provide you with exact expenditure of your project only to realise later that you exceeded you budget\n \n \nAdvantages :\n \n> Free wide range of multiple themed designs for every room size to satisfy all your needs. If still you want more design options we will provide it without any extra charges\n \n> Quick Implementation & guarantee of finished product. We will bear the extra cost in case of any mishappening in your home interiors. We take all your worries. We also provide you the maximum warranty for the materials used to implement your interiors\n \n> No 3rd party hidden charges (either contractor or designer charges). We believe in providing Full Transparency to you which assures you of No 3rd party hidden charges\n \n> At Walscape we provide you with the exact expenditure of your project at the very beginning and you are assured that you will stay within your desired budget without losing any extra penny',
            isMultiline : true,
            number: '04.',
        },
        {
            question : 'Can I bring my own contractor to execute the design?',
            answer : 'Designing your home interiors through a contractor/ individual designer/ studio would essentially mean extensive amount of research for choosing right materials, finishes and colours, sourcing furniture, coordinating with painters, carpenters, hoards of labour and no guarantee of finished product. So we take projects only on turnkey basis only for your benefits',
            isMultiline : false,
            number: '05.',
        },
        {
            question : 'What kind of designers will work on my Customisation home project?',
            answer : 'Our Principal Designer and company director Mrs. Neha Sharma with 12+ years of experience in Home interiors industry will personally take care of your project',
            isMultiline : false,
            number: '06.',
        },
        {
            question : 'Can I stay in the house if my home is getting renovated?',
            answer : 'Yes you can definitely  stay while we implement your interiors. In case you want to stay, we will need a particular unit free for renovating the unit',
            isMultiline : false,
            number: '07.',
        },
        {
            question : 'What is covered under the final project cost?',
            answer : 'We will provide you a BoQ (Bill of quantities) in which you will get to know about each and every fine detail of material cost, labour cost, furniture cost etc',
            isMultiline : false,
            number: '08.',
        },
        {
            question : 'What are the pre-requisites of the Walscape Warranty?',
            answer : 'The warranty is provided by the individual companies whose materials are being used in the project. Therefore in case of repair of any object, you need to directly contact the company and show the warranty card which will be provided to you along with BoQ. The individual companies shall be liable in case of any repairs/replacements',
            isMultiline : false,
            number: '09.',
        },
        {
            question : 'Which are the regions Walscape currently operates in?',
            answer : 'Walscape is currently operational in Delhi NCR region, Punjab, Haryana & Rajasthan. We will soon expand and provide our services to the other states',
            isMultiline : false,
            number: '10.',
        },
        {
            question : 'Does Walscape undertake civil work?',
            answer : 'As of now we only cater interior solutions',
            isMultiline : false,
            number: '11.',
        },
        {
            question : 'If I have any queries/concerns, who do I get in touch with?',
            answer : 'For any queries please feel  free to  contact our customer care team which is more than happy to help you!\n \nPhone >\n \nEmail >\n \nYou can also use the live chat option on our website ',
            isMultiline : true,
            number: '12.',
        },
        {
            question : 'How many design iterations can I request?',
            answer : 'Don’t worry we will fulfil your every requirement. There’s no limit to the number of iterations you can request. However, we recommend keeping iterations to a maximum of three or four because each revision will affect projected timelines',
            isMultiline : false,
            number: '13.',
        },
        {
            question : 'How soon can I expect work to begin?',
            answer : 'We will start implementing your interiors as soon as you are satisfied with the design and all your needs have been fulfilled and you are happy to start your journey with us',
            isMultiline : false,
            number: '14.',
        }
    ]
}

export const ContactDetailsText={
    phone: ["7426982004", "8239070741"],
    email: "support@walscape.com"
}

export const BrowseDesignsSeoText = {
    kitchen: {
        heading1:"Kitchen Interiors : Best Modular Kitchen in Delhi, Gurgaon, Noida, Chandigarh, Punjab, Haryana and Rajasthan",
        description1:"Interiors for a modular kitchen are a constantly changing field with the kitchen no longer being just a cooking abode but also a delightful area to munch at home. Competing for the best, the kitchen interior needs to be competent, practical, functional and aesthetically sound. Kitchen Decor ideas should include understanding the family’s habits. It will be easier and less of a struggle if your Modular kitchen is organised and has storage space for efficiency. A design requires sophistication and a quality of neatness. We are currently providing kitchen interior services in Delhi, Gurugram, Noida, Ghaziabad, Chandigarh, Rajasthan, Punjab and Haryana",
        heading2:"Why Choose Walscape to beautify your Kitchen?",
        description2:"At Walscape, we provide you vast styling range of modular kitchen interior designs to match your personality. Browse different styles and themes of kitchen and select the best one that suits your home decor ideas and get in touch with us, we will install your kitchen interiors at best price guaranteed also in just 8 weeks. Get your kitchen interiors done at best competitive price in Delhi , Gurugram, Noida, Chandigarh, Punjab , Rajasthan and Haryana.",
        heading3:"Types of kitchen designs available on Walscape",
        types:[
            { title:"Straight Kitchen" },
            { title:"U – Shaped Kitchen" },
            { title:"L - Shaped Kitchen" },
            { title:"Parallel Kitchen" },
            { title:"U – Shaped Kitchen with Island" },
            { title:"L – Shaped Kitchen with Island" },
            { title:"Straight Island Kitchen" },
            { title:"Parallel Shaped Kitchen with island" }
        ]
    },
    bedroom: {
        heading1:"One stop for complete bedroom interior needs",
        description1:"At home, the bedroom is known to be a cosy escape into blissful relaxation. The Bedroom interiors should whisper solitude and comfort. The bedrooms are designed and decorated with attention to every minute detail. Keeping your desires at priority, beautiful furnishings and amazing aesthetics are in the package of Interiors of Bedroom. We create your dream bedroom in just 8 weeks.",
        heading2:"Installing bedroom interiors without any hassle is now made possible at Walscape",
        description2:"At Walscape, we provide you a vast styling range of Bedroom interior designs to take your comfort level at its peak. Browse various styles of Bedroom interiors and select the best one for you according to your needs and contact us, we will implement your interiors at best price guaranteed also in just 8 weeks.  We provide a wide range of design options from master bedroom to kid’s bedroom decor ideas. Get your bedroom interiors done at best competitive price in Delhi , Gurugram, Noida, Chandigarh, Punjab , Rajasthan and Haryana.",
        heading3:"Various bedroom design themes available on Walscape",
        types:[
            {
                title:"Contemporary Design",
                description:"Contemporary bedroom interior combines chicness, sleekness and boldness in a fashionable method. It displays pops of colors in a sophisticated manner, with a glow of exquisiteness."
            },
            {
                title:"Modern Design",
                description:"The modern bedroom centres around wooden furnishings  and a great functionality. This interior theme circles around an angular design, additionally it embraces an era of today."
            },
            {
                title:"Rustic Design",
                description:"Rustic bedroom interior combines the outdoors and natural elements in order to create a homely feel. It’s the charm which gives it an old country feel which draws the eyes to the effortless elements."
            },
            {
                title:"Minimalist Design",
                description:"The minimalist interior style of bedroom is all about creating a personal space that ultimately empowers a stylish and serene gateway. It pivots around the idea of keeping it low profile and raw."
            },
            {
                title:"Traditional Design",
                description:"A typical traditional bedroom blends in the outdoors; nature such as fruit or weather patterns. It incorporates ornamentation and muted, plush colours like creams, taupe, brownish and light greys. This in turn creates a timeless and evergreen interior theme."
            },
        ]
    },
    diningRoom:{
        heading1:"Dining room interiors from scratch to finish with best quality materials in just 8 weeks.",
        description1:"The interiors of Dining Room is responsible for creating the impression while having a meal so it should be designed to make the vibes excellent.  The dining room interiors acquires to grab your attention and appeal to your personality. It needs to make people feel something as it is a splash of your home and character. The colour palette ought to include inviting tones to attract the guests as the lobby is the root of positive vibes. At Walscape, we promise to complete your dining room interiors in just 8 weeks. We are currently providing interior services in Delhi , Gurugram, Noida, Chandigarh, Punjab , Rajasthan and Haryana.",
        heading2:"Dining room interiors made easy at Walscape",
        description2:"At Walscape, we provide you the best styling range of Dining room interior designs to fulfil your needs of your dream house. Browse for awesome various styles of Lobby designs and select the design meant  for you and just contact us. We will implement the interiors of your dining room according to your needs and budget. No need to pay third party charges and extra designing charges at Walscape.",
        heading3:"Different Dining Room Interior Themes available on Walscape",
        types:[
            {
                title:"Modern Design",
                description:"A modern lobby interiors seeks to indulge itself in today’s era. It pops out with its luxurious and well-appointed atmosphere. The modern interior echoes freshness and sublime sleekness. "
            },
            {
                title:"Contemporary Design",
                description:"The contemporary lobby design style is always eager to impress. Coordinating traditional style with modern design, it speaks to the soul. It’s striking and systematic as the style controls chaos and defines a new habitat."
            },
            {
                title:"Traditional Design",
                description:"Traditional home interior is often mistaken for ‘old’ and ‘forgotten’, which is a huge misconception. This traditional Dining room interior reaches out to all eras, and redefines timeless beauty."
            },
            {
                title:"Transitional Design",
                description:"The transitional Lobby look is magnificent in every way as it constitutes two different styles; contemporary and traditional. It’s fresh, funky and favourable."
            },
            {
                title:"Rustic Design",
                description:"The rustic interiors goes for groovy designs and articulates magnetism and country vibes. It combines together pieces from the past, enlightening the home."
            },
            {
                title:"Minimalist Design",
                description:"This style, although simple, is loud enough to boom and dazzle spectators. It is clean, efficient and tidy."
            },
        ]
    },
    livingRoom:{
        heading1:"Living Room Interiors at best price and unmatched quality in just 8 weeks.",
        description1:"The living rooms interiors once done have the urge to unite the people together. The environment must be harmonious and homey. It should fit in with the requirement. Designing interiors for living room must suit the style and the key aspect is its aesthetics. Balancing out textures, furniture, wall decoration is essential and the impression of a brilliant living room lies in everyone’s eyes. Get it done in just 8 weeks without any designing or third party charges.",
        heading2:"Living room interiors is made hassle free and easy",
        description2:"At Walscape, we provide you best styling options of Living Room interiors to cater all your needs of dream home. Browse for awesome styles of living room designs and choose the best one meant for you. We will implement the interiors of  your living room at best price and best materials guaranteed in just 8 weeks. We are currently providing interior services in Delhi , Gurugram, Noida, Chandigarh, Punjab , Rajasthan and Haryana.",
        heading3:"Different living room interior themes available on Walscape",
        types:[
            {
                title:"Modern Design",
                description:"The terms smooth, sleek, polished and streamlined come to mind when you think of this modern living room interiors. It is characterised by neutral tones, keeping forth asymmetry and bold shapes. There are no unnecessary decorations which make the living room space seem smaller. The ideal modern living area has to be airy, practical and functional."
            },
            {
                title:"Traditional Design",
                description:"Traditional living room design features timeless furnishings that can still be able to look fresh and unforced for years. Enhancing the environment with wooden interiors as well as throwing a dash of metallics creates a sensational feel."
            },
            {
                title:"Contemporary Design",
                description:"The Contemporary  theme of living room interiors focuses on soft and natural elements with burst of colourful features. Accessories in addition adds a vibrant hue."
            },
            {
                title:"Transitional Design",
                description:"The transitional living room interiors is a downright refined style which interlocks traditional and contemporary designs. The colour palette is neutral. "
            },
            {
                title:"Rustic Design",
                description:"A Rustic living room illustrates that wooden texture in an immortal fashion. The walls adding tradition in the air, emitting warmth as well as charm. Urban elements bind the living room together, making it a perfect picture."
            },
            {
                title:"Minimalist Design",
                description:"Minimalist theme of living room calls for integration, sleekness and glossiness. This design theme echoes a science fiction environment by its streamlined and professional atmosphere."
            },
        ]
    }
}
  
import React, { Component } from "react";
import "./footer.css";
import { PintrestIcon,InstagramIcon,LinkedInIcon,TwitterIcon,FacebookIcon, Logo} from "../../images/_imagesRootImport";
import { Link } from "gatsby";

const footerLinks1 = [
  { link : "/contact-us", title:"Contact Us" },
  { link : "/about-us", title:"About Us" },
  { link : "/policies", title:"Policies" },
  { link : "/terms", title:"Terms of service" }
]
const footerLinks2 = [
  { link : "/living-room-designs", title:"Living Room" },
  { link : "/bedroom-designs", title:"Bedroom" },
  { link : "/dining-room-designs", title:"Dining Room" },
  { link : "/home-interiors-custom-designs", title:"Customization" }  
]
const footerSocialLinksData = [
  { link : "https://www.facebook.com/walscape.in/", src:FacebookIcon },
  { link : "https://www.linkedin.com/company/walscape", src:LinkedInIcon },
  { link : "https://www.instagram.com/walscape", src:InstagramIcon },
  { link : "https://in.pinterest.com/walscape", src:PintrestIcon }  
]

class Footer extends Component {
  render() {
    return (
      <footer className="footerContainer">
          <div className="logoAndTitleContainer">
              <div className="logo">
                <img src={Logo} className="logoimg"/>
              </div>
              <h1 className="companyTitle">WALSCAPE</h1>

              <SocialLinks data={footerSocialLinksData}/>

              <p className="copyrightText">© 2020 WALSCAPE INTERIOR SOLUTIONS INDIA</p>
              <p className="allRightsReservedText">ALL RIGHTS RESERVED</p>
          </div>

          <div className="rightFooterContainer">
            <FooterLinks id="" linkData ={footerLinks1}/>
            <FooterLinks id="rightFooterHide" linkData ={footerLinks2}/>
          </div>
      </footer>
    );
  }
}

class FooterLinks extends Component{
  render(){
    return (
      <div className="rightFooter" id={this.props.id}>
        <div className="rightFooterSeparator"></div>
        {this.props.linkData.map(this.GetView)}
      </div>
    );
  }

  GetView(data){
    return(
      <Link to={data.link} className="gatsbyLink"><p className="content">{data.title}</p></Link>
    );
  }
}

class SocialLinks extends Component{
  render(){
    return (
      <div className="social">
      {this.props.data.map(
          e => {
            return <a href={e.link}><img className="socialLink" src={e.src}/></a>    
          }
        )}
      </div>
    );
  }
}

export default Footer;

//Layout => Header
export {default as MenuIcon } from './outline_menu_black_18dp.png';
export {default as Logo } from './logo.png';

//Layout => Header
export {default as LinkedInIcon } from './linkedin.png';
export {default as TwitterIcon } from './twitter.png';
export {default as FacebookIcon } from './fb.png';
export {default as InstagramIcon } from './instagram.png';
export {default as PintrestIcon } from './pin_interest.png';

//index => DesignSolutions
export {default as HomeBackground } from './home.jpg';

//index => WhyChooseUS
export {default as Image360 } from './360_icon.png';
export {default as ImageWarranty } from './warrenty.png';
export {default as ImageTransparency } from './transparency.png';

//index => TrustThePros
export {default as LeftImage } from './trust_the_pros_large.jpg';
export {default as RightImage1 } from './trust_the_pros_small2.jpg';
export {default as RightImage2 } from './trust_the_pros_small1.jpg';

//index => HappyCustomers
export {default as FirstUserImage1 } from './first_user_image1.jpg';
export {default as FirstUserImage2 } from './first_user_image2.jpg';
export {default as FirstUserImage3 } from './first_user_image3.jpg';
export {default as FirstUserImage4 } from './first_user_image4.jpg';
export {default as FirstUserImage5 } from './first_user_image5.jpg';

export {default as SecondUserImage1 } from './second_user_image1.jpg';
export {default as SecondUserImage2 } from './second_user_image2.jpg';
export {default as SecondUserImage3 } from './second_user_image3.jpg';
export {default as SecondUserImage4 } from './second_user_image4.jpg';
export {default as SecondUserImage5 } from './second_user_image5.jpg';
export {default as SecondUserImage6 } from './second_user_image6.jpg';
export {default as SecondUserImage7 } from './second_user_image7.jpg';
export {default as SecondUserImage8 } from './second_user_image8.jpg';
export {default as SecondUserImage9 } from './second_user_image9.jpg';

export {default as ThirdUserImage1 } from './third_user_image1.jpg';
export {default as ThirdUserImage2 } from './third_user_image2.jpg';
export {default as ThirdUserImage3 } from './third_user_image3.jpg';
export {default as ThirdUserImage4 } from './third_user_image4.jpg';
export {default as ThirdUserImage5 } from './third_user_image5.jpg';
export {default as ThirdUserImage6 } from './third_user_image6.jpg';
export {default as ThirdUserImage7 } from './third_user_image7.jpg';

export {default as FourthUserImage1 } from './fourth_user_image1.jpg';
export {default as FourthUserImage2 } from './fourth_user_image2.jpg';
export {default as FourthUserImage3 } from './fourth_user_image3.jpg';
export {default as FourthUserImage4 } from './fourth_user_image4.jpg';
export {default as FourthUserImage5 } from './fourth_user_image5.jpg';

export {default as FifthUserImage1 } from './fifth_user_image1.jpg';
export {default as FifthUserImage2 } from './fifth_user_image2.jpg';
export {default as FifthUserImage3 } from './fifth_user_image3.jpg';
export {default as FifthUserImage4 } from './fifth_user_image4.jpg';
export {default as FifthUserImage5 } from './fifth_user_image5.jpg';
export {default as FifthUserImage6 } from './fifth_user_image6.jpg';
export {default as FifthUserImage7 } from './fifth_user_image7.jpg';

export {default as SixthUserImage1 } from './sixth_user_image1.jpg';
export {default as SixthUserImage2 } from './sixth_user_image2.jpg';
export {default as SixthUserImage3 } from './sixth_user_image3.jpg';
export {default as SixthUserImage4 } from './sixth_user_image4.jpg';
export {default as SixthUserImage5 } from './sixth_user_image5.jpg';

//index => DropMessage
export {default as Call } from './call.png';
export {default as Mail } from './mail.png';
export {default as CallBlack } from './call_black.png';
export {default as MailBlack } from './mail_black.png';

//customization
export {default as CustomizationArrowImage } from './primary_arrow.png';

//aboutus
export {default as AboutUsStoryImage } from './about_us_story.jpg';
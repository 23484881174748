import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { MenuIcon, Logo } from '../../images/_imagesRootImport'
import "./header.css";

class Header extends Component{
  constructor(props){
    super(props);

    this.state = {scrollPostion: 0};
  }
  componentDidMount()  {
    this.SetLocationTextVisibility();
    this.SetSelectedLinkColor();
    this.HandleWindowScroll();

    window.onscroll = () => this.HandleWindowScroll();
  }

  componentWillUnmount(){
    window.onscroll = null
  }
    

  HandleWindowScroll(){
    var navContainer = document.getElementById("navContainer");
    navContainer.style.boxShadow = window.scrollY > 0 
      ? "var(--shadow-color-light2)" : "none";

    if(window.scrollY > this.state.scrollPostion)
    {
      navContainer.style.boxShadow = "none";
      navContainer.style.height = "0vh";
    }
    else
      navContainer.style.height = !this.doesntShowLocation() ? "11vh" : "8vh";
    this.setState({scrollPostion: window.scrollY});
  }

  doesntShowLocation() 
  {
    return window.innerWidth < 600
  } 
  
  SetSelectedLinkColor() {
    var selectedLink = document.getElementById(this.props.selectedPage);
    if(selectedLink != null)
      selectedLink.style.color = "var(--primary-color)";
  }

  SetLocationTextVisibility() {
    var location = document.getElementById('locationText');
    location.style.lineHeight = !this.doesntShowLocation() ?  "3vh": "0vh";
  }

  render(){
    return(
      <div id="navContainer"  className="navContainer">
          <p id= "locationText" className="locationText">Currently serving in Delhi NCR, Bengaluru, Hyderabad, Rajasthan, Punjab and Haryana.</p>

          <nav class="navbar">
              <span class="navbar-toggle" id="js-navbar-toggle" onClick={handleClick}>
                  <img src={MenuIcon} className="menuIcon"/>
              </span>

              <Link className="link" to="/">
                  <div className="CompanyLogoTitle">
                      <img src={Logo} className="LogoImage"/>
                      <text className="TitleText">{this.props.siteTitle.toUpperCase()}</text>
                  </div>
              </Link>

              <div className="mainNavContainer" id="mainNavContainer" onClick={handleClose}>
              <ul class="main-nav" id="js-menu">
                  <li><Link to="/living-room-designs" className="nav-links" id="browseDesigns">
                    BROWSE DESIGNS</Link></li>
                  <li><Link to="/home-interiors-custom-designs" className="nav-links" id="customization">
                    CUSTOMIZATION</Link></li>
                  <li><Link to="/about-us" className="nav-links" id="aboutUs">
                    ABOUT US</Link></li>
                 <li><Link to="/contact-us" className="nav-links" id="contactUs">
                    CONTACT US</Link></li>
              </ul>
              </div>
          </nav>

      </div>
    );
  }
}

function handleClick(){
  var mainNav = document.getElementById('mainNavContainer');
  mainNav.classList.add('drawerOpenBg');

  var mainNav = document.getElementById('js-menu');
  mainNav.classList.add('drawerOpen');
}

function handleClose(){
  var mainNav = document.getElementById('js-menu');
  mainNav.classList.remove('drawerOpen');

  setTimeout(() => {
    var mainNav = document.getElementById('mainNavContainer');
    mainNav.classList.remove('drawerOpenBg');    
  }, 500);
}

Header.propTypes = {siteTitle: PropTypes.string}
Header.defaultProps = {siteTitle: ''}

export default Header
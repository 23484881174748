import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './header'
import Footer from './footer'
import './layout.css'
import './colors.css'
import './dimen_calc.css'

function Layout(data, selectedPage, children) {
  return (
    <div className="layout">
      <Header siteTitle={data.site.siteMetadata.title} selectedPage={selectedPage}/>
        <div className="layoutBody">
          {children}
        </div>
      <Footer />
    </div>
  );
}

const LayoutStaticQuery = ({ children, selectedPage}) => (
  <StaticQuery
    query={graphql`query SiteTitleQuery{site{siteMetadata{title}}}`}
    render={data => (Layout(data, selectedPage, children))}/>
)

LayoutStaticQuery.propTypes = {children: PropTypes.node.isRequired}

export default LayoutStaticQuery

